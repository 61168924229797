import React from 'react';
import Button from '../Button';
import scheduleModalImage from '../../assets/images/illustrations/schedule-modal.png';
import ModalHeading from './ModalHeading';
import { BUTTON_MODAL } from '../../constants';
import { useCallback } from 'react';
import { navigate } from 'gatsby';
import useWindow from '../../hooks/useWindow';
import useSSR from '../../hooks/useSSR';

function AppointmentConfirmedModal({ handleClick, startTime }) {
  const { isMobile } = useWindow()
  const isSSR = useSSR()

  const onClick = useCallback(() => {
    handleClick()

    if(isMobile) {
      console.log(navigator.userAgent.toLowerCase().indexOf("android"))
      if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
        navigate('https://play.google.com/store/apps/details?id=com.sprive')
      }
      if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
        navigate('https://apps.apple.com/us/app/sprive-mortgage-free-faster/id1504836142')
      }
    }
    else {
      if(!isSSR) {
        localStorage.setItem("bonusModalState", "open")
      }
      navigate("/")
    }
  }, [isMobile])
  
  return (
    <div className="info-modal appointment-modal">
      <img src={scheduleModalImage} />
      <div className="info-modal__heading-container">
        <ModalHeading
          title="Great! Appointment confirmed"
          subtitle={
            <>
              Our expert will call you between
              <span>
                {startTime?.toFormat && startTime.toFormat(`h${(startTime.toFormat("m") == 0) ? "" : ".mm"} a`)} - {" "}
                {startTime?.toFormat && startTime?.plus({minutes: 30})?.toFormat(`h${(startTime?.plus({minutes: 30})?.toFormat("m") == 0) ? "" : ".mm"} a`)}
              </span>
              <br />
              on <span className="no-margin">{startTime?.toFormat && startTime?.toFormat('cccc, LLL dd')}</span>
              <br />
              <br />
              <br />
              Please check your email for more information
            </>
          }
        />
      </div>
      <div className="info-modal__button-container">
        <Button
          buttonText="Okay"
          height="56px"
          size={BUTTON_MODAL}
          handleClick={onClick}
        />
      </div>
    </div>
  );
}

export default AppointmentConfirmedModal;
