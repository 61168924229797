import { useEffect } from "react"
import { useState } from "react"

function useSSR() {
    const [isSSR, setIsSSR] = useState(null)

    useEffect(() => {
        if(typeof window !== 'undefined') {
            setIsSSR(false)
        }
        else {
            setIsSSR(true)
        }
    }, [])

    return isSSR
}

export default useSSR